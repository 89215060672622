import { GetStaticProps, GetStaticPaths } from 'next';

// ... Shared package components
import { BannerDetails } from '@shared/utilities/types/Banners';
import typeStore from '@shared/utilities/types/Store';
import typeProduct from '@shared/utilities/types/Product';
import typeMenus from '@shared/utilities/types/Menus';

import { generateSitemap } from '@/utilities/helpers/Sitemap';
import { isSpamPath } from '@/utilities/helpers/SpamChecker';
import Layout from '@/components/pages/Layout';
import pathApps from '@/utilities/paths/Apps';
import pathAuthors from '@/utilities/paths/Authors';
import pathChecklists from '@/utilities/paths/Checklists';
import pathCustomerStories from '@/utilities/paths/CustomerStories';
import pathEbooks from '@/utilities/paths/Ebooks';
import pathEvents from '@/utilities/paths/Events';
import pathLegal from '@/utilities/paths/Legal';
import pathPage from '@/utilities/paths/Page';
import pathPartnerships from '@/utilities/paths/Partnerships';
import pathTopics from '@/utilities/paths/Topics';
import propApp from '@/utilities/props/App';
import propApps from '@/utilities/props/Apps';
import propAuthor from '@/utilities/props/Author';
import propAuthors from '@/utilities/props/Authors';
import propChecklist from '@/utilities/props/Checklist';
import propChecklists from '@/utilities/props/Checklists';
import propCustomerStories from '@/utilities/props/CustomerStories';
import propCustomerStory from '@/utilities/props/CustomerStory';
import propEbook from '@/utilities/props/Ebook';
import propEbooks from '@/utilities/props/Ebooks';
import propEvent from '@/utilities/props/Event';
import propEvents from '@/utilities/props/Events';
import propLegal from '@/utilities/props/Legal';
import propMenus from '@/utilities/props/Menus';
import propPage from '@/utilities/props/Page';
import propPartnerships from '@/utilities/props/Partnerships';
import propTopic from '@/utilities/props/Topic';
import propTopics from '@/utilities/props/Topics';
import Template from '@/modules/Template';
import Type from '@/utilities/helpers/Type';
import typeBreadcrumbs from '@/utilities/types/Breadcrumbs';
import typePagination from '@/utilities/types/Pagination';
import typePaths from '@/utilities/types/Paths';
import typeSeo from '@/utilities/types/Seo';
import typeStatus from '@/utilities/types/Status';
import WEBSITE from '@/utilities/constants/Website';

interface typePageResults {
  redirect?: string;
}

export interface getPropsResult {
  content: any;
  layout: {
    header?: {
      loginAndSignUpButtonsStatus?: boolean | null;
      languageToggle?: boolean | null;
      notification?: boolean;
      navigationStatus?: boolean | null;
      additionalLogo?: {
        altText?: string;
        guid?: string;
      };
      downloadApp?: boolean | null;
    };
    footer?: {
      mainMenu: boolean;
      leagalMenu: boolean;
    };
    breadcrumbsOn?: boolean | undefined;
    breadcrumbs?: typeBreadcrumbs[];
    pagination?: typePagination;
    product?: typeProduct['override'];
    seo: typeSeo;
    status?: typeStatus['status'];
    store?: typeStore;
  };
}

interface PathsProps {
  data: getPropsResult;
  menus: typeMenus;
  notificationBanner?: {
    critical: BannerDetails;
    nonCritical: BannerDetails;
  };
  type: string;
}

const Paths = (props: PathsProps) => {
  const { data, menus, type, notificationBanner } = props;

  return (
    <Layout
      breadcrumbs={data.layout.breadcrumbs && data.layout.breadcrumbs}
      menus={{
        footer: menus.footer,
        header: menus.header,
        legal: menus.legal,
      }}
      pagination={data.layout.pagination}
      product={data.layout?.product}
      seo={data.layout.seo}
      status={data.layout.status}
      store={data.layout.store}
      notification={notificationBanner}
      customise={{
        header: {
          downloadApp: data.layout?.header
          ? data.layout.header.downloadApp
          : true,
          loginAndSignUpButtonsStatus: data.layout?.header
            ? data.layout.header.loginAndSignUpButtonsStatus
            : true,
          languageToggle: data.layout?.header
            ? data.layout.header.languageToggle
            : true,
          navigationStatus: data.layout?.header
            ? data.layout.header.navigationStatus
            : true,
          notification: data.layout?.header
            ? data.layout.header.notification
            : true,
          additionalLogo: {
            logo: {
              altText:
                data.layout?.header &&
                  data.layout?.header?.additionalLogo?.altText
                  ? data.layout.header.additionalLogo.altText
                  : null,
              guid:
                data.layout?.header && data.layout?.header?.additionalLogo?.guid
                  ? data.layout.header.additionalLogo.guid
                  : null,
            },
          },
        },
        footer: {
          mainMenu: data.layout?.footer ? data.layout?.footer?.mainMenu : true,
          legalMenu: data.layout?.footer
            ? data.layout?.footer?.leagalMenu
            : true,
        },
        breadcrumbsOn:
          data.layout?.breadcrumbsOn !== undefined
            ? data.layout?.breadcrumbsOn
            : true,
      }}
    >
      <Template content={data.content} type={type} />
    </Layout>
  );
};

export default Paths;

export const getStaticPaths: GetStaticPaths = async () => {
  const isDev = process.env.NODE_ENV === 'development';
  const isSandpit =
    process.env.NEXT_PUBLIC_DOMAIN &&
    process.env.NEXT_PUBLIC_DOMAIN.includes('sandpit');

  // For sandpit deployments & development environments, do not generate static pages due the long running build times for the sandpit site
  // Needs more server resources
  if (isDev || isSandpit) {
    // This takes a long time to build otherwise, and it will regenerate them anyway.
    return {
      fallback: 'blocking',
      paths: [],
    };
  }

  const website = process.env.NEXT_PUBLIC_WEBSITE as string;

  const ebooksResults = WEBSITE[website].types.includes('ebooks')
    ? await pathEbooks({
      website,
    })
    : { ebooks: [], ebooksPaths: [], ebooksSitemap: [] };

  const { ebooks, ebooksPaths, ebooksSitemap } = Array.isArray(ebooksResults)
    ? { ebooks: [], ebooksPaths: [], ebooksSitemap: [] }
    : ebooksResults;

  const eventsResults = WEBSITE[website].types.includes('events')
    ? await pathEvents({
      website,
    })
    : { events: [], eventsPaths: [], eventsSitemap: [] };

  const { events, eventsPaths, eventsSitemap } = Array.isArray(eventsResults)
    ? { events: [], eventsPaths: [], eventsSitemap: [] }
    : eventsResults;

  const checklistsResults = WEBSITE[website].types.includes('checklists')
    ? await pathChecklists({
      website,
    })
    : { checklists: [], checklistsPaths: [], checklistsSitemap: [] };

  const { checklists, checklistsPaths, checklistsSitemap } = Array.isArray(
    checklistsResults,
  )
    ? { checklists: [], checklistsPaths: [], checklistsSitemap: [] }
    : checklistsResults;

  const topicsResults = WEBSITE[website].types.includes('topics')
    ? await pathTopics({
      website,
    })
    : { topics: [], topicsPaths: [], topicsSitemap: [] };

  const { topics, topicsPaths, topicsSitemap } = Array.isArray(topicsResults)
    ? { topics: [], topicsPaths: [], topicsSitemap: [] }
    : topicsResults;

  const appsResults = WEBSITE[website].types.includes('apps')
    ? await pathApps({
      website,
    })
    : { apps: [], appsPaths: [], appsSitemap: [] };

  const { apps, appsPaths, appsSitemap } = Array.isArray(appsResults)
    ? { apps: [], appsPaths: [], appsSitemap: [] }
    : appsResults;

  const legalsResults = WEBSITE[website].types.includes('legal')
    ? await pathLegal({
      website,
    })
    : { legal: [], legalsSitemap: [] };

  const { legal, legalsSitemap } = Array.isArray(legalsResults)
    ? { legal: [], legalsSitemap: [] }
    : legalsResults;

  const partnershipsResults = WEBSITE[website].types.includes('partnerships')
    ? await pathPartnerships({
      website,
    })
    : { partnerships: [], partnershipsSitemap: [] };

  const { partnerships, partnershipsSitemap } = Array.isArray(
    partnershipsResults,
  )
    ? { partnerships: [], partnershipsSitemap: [] }
    : partnershipsResults;

  const customerStoriesResults = WEBSITE[website].types.includes('customers')
    ? await pathCustomerStories({
      website,
    })
    : {
      customerStories: [],
      customerStoriesPaths: [],
      customerStoriesSitemap: [],
    };

  const { customerStories, customerStoriesPaths, customerStoriesSitemap } =
    Array.isArray(customerStoriesResults)
      ? {
        customerStories: [],
        customerStoriesPaths: [],
        customerStoriesSitemap: [],
      }
      : customerStoriesResults;

  const pagesResults = await pathPage({ website });

  const { pages, pagesSitemap } = Array.isArray(pagesResults)
    ? { pages: [], pagesSitemap: [] }
    : pagesResults;

  const authorsResults = WEBSITE[website].types.includes('authors')
    ? await pathAuthors({
      website,
    })
    : { authors: [], authorsPaths: [], authorsSitemap: [] };

  const { authors, authorsPaths, authorsSitemap } = Array.isArray(
    authorsResults,
  )
    ? { authors: [], authorsPaths: [], authorsSitemap: [] }
    : authorsResults;

  const allPaths = [
    // ...authors,
    // ...authorsPaths,
    // ...ebooksPaths,
    // ...ebooks,
    // ...eventsPaths,
    // ...events,
    ...legal,
    // ...partnerships,
    ...customerStoriesPaths,
    ...customerStories,
    ...checklistsPaths,
    ...checklists,
    ...appsPaths,
    ...apps,
    ...topicsPaths,
    ...topics,
    ...pages,
  ];

  // data for sitemap
  const sitemapPaths: typePaths[] = [
    ebooksSitemap,
    eventsSitemap,
    partnershipsSitemap,
    customerStoriesSitemap,
    checklistsSitemap,
    appsSitemap,
    topicsSitemap,
    legalsSitemap,
    pagesSitemap,
    authorsSitemap,
  ];

  // generate sitemap
  generateSitemap(sitemapPaths);

  return {
    fallback: 'blocking',
    paths: allPaths,
  };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const website = process.env.NEXT_PUBLIC_WEBSITE as string;

  const locale = context.locale as string;

  const paths = context.params ? (context.params.paths as string[]) : null;

  const isSpam = isSpamPath(paths);

  if (isSpam) {
    return {
      notFound: true,
    };
  }

  const results = [];

  results.push(await propMenus({ locale }));

  const type = Type({ paths, website });

  if (WEBSITE[website].types.includes('ebook') && type === 'ebook' && paths) {
    results.push(await propEbook({ locale, paths, website }));
  } else if (
    WEBSITE[website].types.includes('ebooks') &&
    type === 'ebooks' &&
    paths
  ) {
    results.push(await propEbooks({ locale, paths, website }));
  } else if (
    WEBSITE[website].types.includes('event') &&
    type === 'event' &&
    paths
  ) {
    results.push(await propEvent({ locale, paths, website }));
  } else if (
    WEBSITE[website].types.includes('events') &&
    type === 'events' &&
    paths
  ) {
    results.push(await propEvents({ locale, paths, website }));
  } else if (
    WEBSITE[website].types.includes('checklist') &&
    type === 'checklist' &&
    paths
  ) {

    const checklistResults = await propChecklist({ locale, paths, website }) as typePageResults;
    if (checklistResults?.redirect) {
      return {
        redirect: {
          destination: checklistResults.redirect,
          permanent: false,
        },
      };
    }
    results.push(checklistResults);

  } else if (
    WEBSITE[website].types.includes('checklists') &&
    type === 'checklists' &&
    paths
  ) {

    const checklistsResults = await propChecklists({ locale, paths, website }) as typePageResults;
    // redirect for cats
    if (checklistsResults?.redirect) {
      return {
        redirect: {
          destination: checklistsResults.redirect,
          permanent: false,
        },
      };
    }
    results.push(checklistsResults);

  } else if (
    WEBSITE[website].types.includes('app') &&
    type === 'app' &&
    paths
  ) {

    const appResults = await propApp({ locale, paths, website }) as typePageResults;

    if (appResults?.redirect) {
      return {
        redirect: {
          destination: appResults.redirect,
          permanent: false,
        },
      };
    }
    results.push(appResults);

  } else if (
    WEBSITE[website].types.includes('apps') &&
    type === 'apps' &&
    paths
  ) {

    const appsResults = await propApps({ locale, paths, website }) as typePageResults;;
    // redirect for cats
    if (appsResults?.redirect) {
      return {
        redirect: {
          destination: appsResults.redirect,
          permanent: false,
        },
      };
    }
    results.push(appsResults);

  } else if (
    WEBSITE[website].types.includes('topic') &&
    type === 'topic' &&
    paths
  ) {

    const topicResults = await propTopic({ locale, paths, website }) as typePageResults;
    
    if (topicResults?.redirect) {
      return {
        redirect: {
          destination: topicResults.redirect,
          permanent: false,
        },
      };
    }
    results.push(topicResults);

  } else if (
    WEBSITE[website].types.includes('topics') &&
    type === 'topics' &&
    paths
  ) {

    const topicsResults = await propTopics({ locale, paths, website }) as typePageResults;
    // redirect for cats
    if (topicsResults?.redirect) {
      return {
        redirect: {
          destination: topicsResults.redirect,
          permanent: false,
        },
      };
    }
    results.push(topicsResults);

  } else if (
    WEBSITE[website].types.includes('legal') &&
    type === 'legal' &&
    paths
  ) {
    results.push(await propLegal({ locale, paths, website }));
  } else if (
    WEBSITE[website].types.includes('partnerships') &&
    type === 'partnership' &&
    paths
  ) {
    results.push(await propPartnerships({ locale, paths, website }));
  } else if (
    WEBSITE[website].types.includes('customer') &&
    type === 'customer' &&
    paths
  ) {
    results.push(await propCustomerStory({ locale, paths, website }));
  } else if (
    WEBSITE[website].types.includes('customers') &&
    type === 'customers' &&
    paths
  ) {
    results.push(await propCustomerStories({ locale, website }));
  } else if (
    WEBSITE[website].types.includes('authors') &&
    type === 'customers' &&
    paths
  ) {
    results.push(await propCustomerStories({ locale, website }));
  } else if (
    WEBSITE[website].types.includes('author') &&
    type === 'author' &&
    paths
  ) {
    results.push(await propAuthor({ locale, paths, website }));
  } else if (
    WEBSITE[website].types.includes('authors') &&
    type === 'authors' &&
    paths
  ) {
    results.push(await propAuthors({ locale, paths, website }));
  } else {

    const pageResults = (await propPage({
      locale, paths: paths ? paths : [WEBSITE[website].root], website,
    })) as typePageResults;

    if (pageResults?.redirect) {
      return {
        redirect: {
          destination: pageResults.redirect,
          permanent: false,
        },
      };
    }

    results.push(pageResults);
  }

  const [menus, data] = results;

  const revalidation = getRevalidationTime(type);

  return data
    ? {
      props: {
        data,
        menus,
        type,
      },
      revalidate: false,
    }
    : {
      notFound: true,
    };
};

/**
 * For content that gets updated more frequest revalidation is every 1 min
 * for everything else 30min
 * @param type as string app | checklist | ...
 * @returns time as string
 */

const getRevalidationTime = (type: string) => {
  switch (type) {
    case 'ebook':
      return 300;
    case 'event':
      return 300;
    case 'checklist':
      return 300;
    case 'app':
      return 300;
    case 'topic':
      return 300;
    case 'page':
      return 300;
    case 'customer':
      return 300;
    default:
      return 1800;
  }
};
